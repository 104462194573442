import React, { Component } from "react";
import "./About.css";

class About extends Component {
  render() {
    return (
      <div className="about">
        <h1>
          Hi, my name is Rahul.
        </h1>
        <p>
          Keep watching this space for more updates :-)
        </p>
      </div>
    );
  }
}

export default About;
